export default ({ data }) => {
  return {
    text: data.text,
    title: data.title,
    topBlock: {
      text: data.top_block.text,
      icon: data.top_block.icon,
    },
    bottomBlock: {
      text: data.bottom_block.text,
      icon: data.bottom_block.icon,
    },
  };
};
